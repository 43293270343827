import styled, { css } from 'styled-components'

export const ContainerMixin = css/* styled */ `
  width: 100%;
  max-width: 1920px;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
`

export const RowMixin = css/* styled */ `
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
`

export const ColMixin = css/* styled */ `
  flex: 0 0 100%;
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
`

export const PaddingsContainerMixin = css/* styled */ `
  padding-left: 7.885%;
  padding-right: 7.885%;
`

export const HeaderContainerMixin = css/* styled */ `
  ${ContainerMixin}
  padding-left: 3.26%;
  padding-right: 3.26%;
`

export const DescMixin = css/* styled */ `
  font-family: ${(props) => props.theme.fontMetaPro};
  line-height: 1.4;
  h3 {
    font-size: 38px;
    font-weight: 900;
    text-transform: uppercase;
    margin-bottom: 70px;
    @media (min-width: 1381px) and (max-width: 1599px) {
      font-size: 34px;
    }
    @media (min-width: 1200px) and (max-width: 1380px) {
      font-size: 32px;
    }
    @media (min-width: 992px) and (max-width: 1199px) {
      font-size: 30px;
      margin-bottom: 50px;
    }
    @media (min-width: 768px) and (max-width: 991px) {
      font-size: 28px;
      margin-bottom: 45px;
    }
    @media (min-width: 576px) and (max-width: 767px) {
      font-size: 26px;
      margin-bottom: 40px;
    }
    @media (max-width: 575px) {
      font-size: 22px;
      margin-bottom: 25px;
    }
  }
  p {
    font-size: 32px;
    margin-bottom: 60px;
    @media (min-width: 1381px) and (max-width: 1599px) {
      font-size: 28px;
      margin-bottom: 40px;
    }
    @media (min-width: 1200px) and (max-width: 1380px) {
      font-size: 26px;
      margin-bottom: 40px;
    }
    @media (min-width: 992px) and (max-width: 1199px) {
      font-size: 24px;
      margin-bottom: 35px;
    }
    @media (min-width: 768px) and (max-width: 991px) {
      font-size: 22px;
      margin-bottom: 35px;
    }
    @media (min-width: 576px) and (max-width: 767px) {
      font-size: 20px;
      margin-bottom: 25px;
    }
    @media (max-width: 575px) {
      font-size: 18px;
      margin-bottom: 20px;
    }
    &.numberRule {
      display: flex;
      margin-bottom: 10px;
      .number {
        display: flex;
        color: #fff;
        font-size: 128px;
        // font-weight: 500;
        font-style: italic;
        font-variant-numeric: lining-nums;
        line-height: 0.85;
        padding-right: 15px;
        text-shadow: -0 -2px 0 #000000, 0 -2px 0 #000000, -0 2px 0 #000000,
          0 2px 0 #000000, -2px -0 0 #000000, 2px -0 0 #000000, -2px 0 0 #000000,
          2px 0 0 #000000, -1px -2px 0 #000000, 1px -2px 0 #000000,
          -1px 2px 0 #000000, 1px 2px 0 #000000, -2px -1px 0 #000000,
          2px -1px 0 #000000, -2px 1px 0 #000000, 2px 1px 0 #000000,
          -2px -2px 0 #000000, 2px -2px 0 #000000, -2px 2px 0 #000000,
          2px 2px 0 #000000, -2px -2px 0 #000000, 2px -2px 0 #000000,
          -2px 2px 0 #000000, 2px 2px 0 #000000;
        margin-top: 15px;
        margin-bottom: 5px;
        @media (min-width: 1200px) and (max-width: 1599px) {
          font-size: 80px;
        }
        @media (min-width: 992px) {
          align-items: center;
        }
        @media (min-width: 992px) and (max-width: 1199px) {
          font-size: 75px;
        }
        @media (max-width: 991px) {
          margin-top: 0;
          margin-bottom: 0;
        }
        @media (min-width: 768px) and (max-width: 991px) {
          font-size: 85px;
          padding-right: 30px;
          margin-top: 0;
        }
        @media (min-width: 576px) and (max-width: 767px) {
          font-size: 70px;
          padding-right: 23px;
          margin-bottom: 0;
        }
        @media (max-width: 575px) {
          font-size: 60px;
          margin-bottom: 0;
          padding-left: 10px;
        }
      }
      .title {
        display: flex;
        font-size: 56px;
        font-weight: 700;
        font-style: italic;
        line-height: 1;
        @media (min-width: 1200px) and (max-width: 1380px) {
          font-size: 40px;
        }
        @media (min-width: 992px) {
          align-items: center;
        }
        @media (min-width: 992px) and (max-width: 1199px) {
          font-size: 36px;
        }
        @media (min-width: 768px) and (max-width: 991px) {
          font-size: 50px;
        }
        @media (min-width: 576px) and (max-width: 767px) {
          font-size: 44px;
        }
        @media (max-width: 575px) {
          font-size: 30px;
        }
        br {
          @media (max-width: 991px) {
            display: none;
          }
        }
      }
    }
    &.withNumberRule {
      @media (min-width: 768px) and (max-width: 991px) {
        padding-left: 80px;
      }
      @media (min-width: 576px) and (max-width: 767px) {
        padding-left: 60px;
      }
      @media (max-width: 575px) {
        padding-left: 60px;
      }
    }
    &.titleDesc {
      font-family: ${(props) => props.theme.fontMetaPro};
      font-size: 28px;
      font-weight: 700;
      text-transform: uppercase;
      margin-top: 15px;
      margin-bottom: -10px;
      @media (min-width: 1200px) and (max-width: 1599px) {
        font-size: 27px;
      }
      @media (min-width: 992px) and (max-width: 1199px) {
        font-size: 25px;
      }
      @media (min-width: 768px) and (max-width: 991px) {
      }
      @media (max-width: 767px) {
        line-height: 1.2;
        margin-top: 5px;
        margin-bottom: -3px;
      }
      @media (min-width: 576px) and (max-width: 767px) {
        font-size: 25px;
      }
      @media (max-width: 575px) {
        font-size: 22px;
      }
    }
    &.no-mb {
      margin-bottom: 0;
    }
  }
  strong {
    font-family: ${(props) => props.theme.fontMetaPro};
    font-weight: 700;
  }
  .green {
    color: ${(props) => props.theme.colorGreen};
  }
`

export const TitleMixin = css/* styled */ `
  font-family: ${(props) => props.theme.fontMetaPro};
  font-weight: 900;
  font-style: italic;
  text-transform: uppercase;
  span {
    display: inline-block;
    &.firstText {
      font-size: 90px;
      @media (min-width: 1200px) and (max-width: 1599px) {
        font-size: 68px;
      }
      @media (min-width: 992px) and (max-width: 1199px) {
        font-size: 50px;
      }
      @media (min-width: 768px) and (max-width: 991px) {
        font-size: 44px;
      }
      @media (min-width: 576px) and (max-width: 767px) {
        font-size: 40px;
      }
      @media (max-width: 575px) {
        font-size: 30px;
      }
    }
    &.lastText {
      font-size: 70px;
      @media (min-width: 1200px) and (max-width: 1599px) {
        font-size: 58px;
      }
      @media (min-width: 992px) and (max-width: 1199px) {
        font-size: 40px;
      }
      @media (min-width: 768px) and (max-width: 991px) {
        font-size: 38px;
      }
      @media (min-width: 576px) and (max-width: 767px) {
        font-size: 30px;
      }
      @media (max-width: 575px) {
        font-size: 20px;
      }
    }
  }
`

export const Button = styled.a`
  display: inline-block;
  background-color: ${(props) => props.bgColor && props.bgColor};
  color: ${(props) => props.color && props.color};
  font-family: ${(props) => props.theme.fontMetaPro};
  font-size: 30px;
  text-align: center;
  padding-top: 20px;
  padding-bottom: 20px;
  border-radius: 50px;
  transition: 0.2s ease;
  @media (min-width: 1200px) and (max-width: 1599px) {
    font-size: 26px;
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    font-size: 26px;
    padding-top: 15px;
    padding-bottom: 15px;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    font-size: 24px;
    padding-top: 15px;
    padding-bottom: 15px;
  }
  @media (max-width: 575px) {
    font-size: 22px;
    padding-top: 12px;
    padding-bottom: 12px;
  }
  &:hover {
    background-color: ${(props) => props.bgColorHover && props.bgColorHover};
    color: ${(props) => props.colorHover && props.colorHover};
    opacity: 0.9;
  }
`

export const PriceHeaderQty = styled.div`
  position: relative;
  display: inline-block;
  color: #8f8f8f;
  font-size: 165px;
  font-weight: 700;
  font-style: normal;
  text-transform: uppercase;
  line-height: 1;
  padding-right: 30px;
  @media (min-width: 992px) and (max-width: 1199px) {
    font-size: 126px;
    padding-right: 20px;
  }
  @media (max-width: 991px) {
    padding-right: 0;
  }
  @media (min-width: 768px) and (max-width: 991px) {
    font-size: 110px;
    padding-top: 20px;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    font-size: 100px;
    padding-top: 20px;
  }
  @media (max-width: 575px) {
    font-size: 80px;
    padding-top: 15px;
  }
  span {
    display: inline-block;
    &:first-of-type {
      vertical-align: text-top;
    }
  }
  .qtyWrapper {
    display: inline-block;
  }
  .qtyText {
    right: -45px;
    bottom: 84px;
    font-family: ${(props) => props.theme.fontMetaPro};
    font-size: 22px;
    @media (min-width: 992px) {
      position: absolute;
      transform: rotate(-90deg);
    }
    @media (min-width: 992px) and (max-width: 1199px) {
      right: -40px;
      bottom: 63px;
      font-size: 17px;
    }
    @media (max-width: 991px) {
      display: block;
      text-align: center;
    }
    @media (min-width: 768px) and (max-width: 991px) {
      font-size: 20px;
      margin-top: -15px;
    }
    @media (min-width: 576px) and (max-width: 767px) {
      font-size: 18px;
      margin-top: -12px;
    }
    @media (max-width: 575px) {
      font-size: 16px;
      margin-top: -10px;
    }
  }
`

export const WinnersList = styled.ul`
  color: ${(props) => props.theme.colorWhite};
  font-size: 28px;
  font-weight: 700;
  line-height: 1.4;
  list-style-type: square;
  padding-left: 30px;
  padding-bottom: 30px;

  @media (min-width: 992px) and (max-width: 1199px) {
    font-size: 24px;
  }
  @media (min-width: 768px) and (max-width: 991px) {
    font-size: 22px;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    font-size: 20px;
  }
  @media (max-width: 575px) {
    font-size: 18px;
    padding-bottom: 0;
  }
  @media (max-width: 340px) {
    font-size: 16px;
  }

  li {
    line-height: 1em;
    margin-bottom: 10px;
    text-transform: uppercase;
  }
`

export const HeaderTitleWithLines = styled.h2`
  text-align: center;
  .textWrapper {
    position: relative;
    display: inline-block;
    color: ${(props) => props.theme.colorGreen};
    font-family: ${(props) => props.theme.fontMetaPro};
    font-weight: 900;
    font-size: 154px;
    font-style: italic;
    text-align: center;
    text-transform: uppercase;
    line-height: 1.1;
    ${(props) =>
      props.page === 'zwyciezcy' &&
      `
      font-size: 94px;
    `}
    @media (min-width: 1381px) and (max-width: 1599px) {
      ${(props) =>
        props.page === 'zwyciezcy' &&
        `
        font-size: 74px;
      `}
    }
    @media (min-width: 1200px) and (max-width: 1380px) {
      ${(props) =>
        props.page === 'zwyciezcy' &&
        `
        font-size: 64px;
      `}
    }
    @media (min-width: 992px) and (max-width: 1199px) {
      font-size: 120px;
      ${(props) =>
        props.page === 'zwyciezcy' &&
        `
        font-size: 60px;
      `}
    }
    @media (max-width: 991px) {
    }
    @media (min-width: 768px) and (max-width: 991px) {
      font-size: 100px;
      ${(props) =>
        props.page === 'zwyciezcy' &&
        `
        font-size: 50px;
      `}
    }
    @media (min-width: 576px) and (max-width: 767px) {
      font-size: 70px;
      ${(props) =>
        props.page === 'zwyciezcy' &&
        `
        font-size: 40px;
      `}
    }
    @media (max-width: 575px) {
      font-size: 50px;
      ${(props) =>
        props.page === 'zwyciezcy' &&
        `
        font-size: 22px;
      `}
    }
    & > span {
      & > span {
        display: block;
        color: ${(props) => props.theme.colorWhite};
        &:first-child {
          font-size: 94px;
          @media (min-width: 1381px) and (max-width: 1599px) {
            font-size: 74px;
          }
          @media (min-width: 1200px) and (max-width: 1380px) {
            font-size: 64px;
          }
          @media (min-width: 992px) and (max-width: 1199px) {
            font-size: 60px;
          }
          @media (min-width: 768px) and (max-width: 991px) {
            font-size: 50px;
          }
          @media (min-width: 576px) and (max-width: 767px) {
            font-size: 40px;
          }
          @media (max-width: 575px) {
            font-size: 22px;
          }
        }
        &:last-child {
          font-size: 44px;
          margin-top: 3px;
          @media (min-width: 1381px) and (max-width: 1599px) {
            font-size: 34px;
          }
          @media (min-width: 1200px) and (max-width: 1380px) {
            font-size: 30px;
          }
          @media (min-width: 992px) and (max-width: 1199px) {
            font-size: 28px;
          }
          @media (min-width: 768px) and (max-width: 991px) {
            font-size: 24px;
          }
          @media (min-width: 576px) and (max-width: 767px) {
            font-size: 18px;
          }
          @media (max-width: 575px) {
            font-size: 12px;
          }
        }
      }
    }
    .line {
      position: absolute;
      display: block;
      width: 2000px;
      height: 0.32em;
      ${(props) =>
        props.page === 'zwyciezcy' &&
        `
        height: 0.62em;
      `}
      &.first {
        top: 0;
        background-color: #fff227;
      }
      &.middle {
        top: calc(50% - 0.16em);
        background-color: #008139;
        ${(props) =>
          props.page === 'zwyciezcy' &&
          `
          top: calc(50% - 0.32em);
        `}
        @media (max-width: 575px) {
          ${(props) =>
            props.page === 'zwyciezcy' &&
            `
            top: calc(50% - 0.36em);
          `}
        }
      }
      &.last {
        background-color: #ff1731;
        bottom: 0;
      }
      &.left {
        left: 0;
        transform: skew(-15deg, 0);
        margin-left: -2000px;
        &.first {
          margin-left: -2020px;
          ${(props) =>
            props.page === 'zwyciezcy' &&
            `
            margin-left: -2014px;
          `}
          @media (min-width: 1381px) and (max-width: 1599px) {
            ${(props) =>
              props.page === 'zwyciezcy' &&
              `
              margin-left: -2019px;
            `}
          }
          @media (min-width: 1200px) and (max-width: 1380px) {
            ${(props) =>
              props.page === 'zwyciezcy' &&
              `
              margin-left: -2024px;
            `}
          }
          @media (min-width: 992px) and (max-width: 1199px) {
            ${(props) =>
              props.page === 'zwyciezcy' &&
              `
              margin-left: -2025px;
            `}
          }
          @media (min-width: 768px) and (max-width: 991px) {
            margin-left: -2024px;
            ${(props) =>
              props.page === 'zwyciezcy' &&
              `

            `}
          }
          @media (min-width: 576px) and (max-width: 767px) {
            margin-left: -2024px;
            ${(props) =>
              props.page === 'zwyciezcy' &&
              `

            `}
          }
          @media (max-width: 575px) {
            margin-left: -2015px;
            ${(props) =>
              props.page === 'zwyciezcy' &&
              `

            `}
          }
        }
        &.middle {
          margin-left: -2034px;
          ${(props) =>
            props.page === 'zwyciezcy' &&
            `
            margin-left: -2031px;
          `}
          @media (min-width: 1381px) and (max-width: 1599px) {
            ${(props) =>
              props.page === 'zwyciezcy' &&
              `
              margin-left: -2034px;
            `}
          }
          @media (min-width: 1200px) and (max-width: 1380px) {
            ${(props) =>
              props.page === 'zwyciezcy' &&
              `
              margin-left: -2036px;
            `}
          }
          @media (min-width: 992px) and (max-width: 1199px) {
            ${(props) =>
              props.page === 'zwyciezcy' &&
              `
              margin-left: -2037px;
            `}
          }
          @media (min-width: 768px) and (max-width: 991px) {
            margin-left: -2035px;
            ${(props) =>
              props.page === 'zwyciezcy' &&
              `

            `}
          }
          @media (min-width: 576px) and (max-width: 767px) {
            margin-left: -2032px;
            ${(props) =>
              props.page === 'zwyciezcy' &&
              `

            `}
          }
          @media (max-width: 575px) {
            margin-left: -2021px;
            ${(props) =>
              props.page === 'zwyciezcy' &&
              `

            `}
          }
        }
        &.last {
          margin-left: -2048px;
          @media (min-width: 768px) and (max-width: 991px) {
            margin-left: -2045px;
          }
          @media (min-width: 576px) and (max-width: 767px) {
            margin-left: -2039px;
          }
          @media (max-width: 575px) {
            margin-left: -2025px;
          }
        }
      }
      &.right {
        right: 0;
        transform: skew(-15deg, 0);
        margin-right: -2000px;
        &.first {
          margin-right: -2048px;
          ${(props) =>
            props.page === 'zwyciezcy' &&
            `
            margin-right: -2055px;
          `}
          @media (min-width: 1381px) and (max-width: 1599px) {
            ${(props) =>
              props.page === 'zwyciezcy' &&
              `
              margin-right: -2050px;
            `}
          }
          @media (min-width: 1200px) and (max-width: 1380px) {
            ${(props) =>
              props.page === 'zwyciezcy' &&
              `
              margin-right: -2046px;
            `}
          }
          @media (min-width: 992px) and (max-width: 1199px) {
            ${(props) =>
              props.page === 'zwyciezcy' &&
              `
              margin-right: -2045px;
            `}
          }
          @media (min-width: 768px) and (max-width: 991px) {
            margin-right: -2045px;
            ${(props) =>
              props.page === 'zwyciezcy' &&
              `
              margin-right: -2044px;
            `}
          }
          @media (min-width: 576px) and (max-width: 767px) {
            margin-right: -2039px;
            ${(props) =>
              props.page === 'zwyciezcy' &&
              `
              margin-right: -2038px;
            `}
          }
          @media (max-width: 575px) {
            margin-right: -2025px;
            ${(props) =>
              props.page === 'zwyciezcy' &&
              `
              margin-right: -2024px;
            `}
          }
        }
        &.middle {
          margin-right: -2034px;
          ${(props) =>
            props.page === 'zwyciezcy' &&
            `
            margin-right: -2037px;
          `}
          @media (min-width: 1381px) and (max-width: 1599px) {
            ${(props) =>
              props.page === 'zwyciezcy' &&
              `
            margin-right: -2034px;
          `}
          }
          @media (min-width: 1200px) and (max-width: 1380px) {
            ${(props) =>
              props.page === 'zwyciezcy' &&
              `
              margin-right: -2032px;
            `}
          }
          @media (min-width: 992px) and (max-width: 1199px) {
            ${(props) =>
              props.page === 'zwyciezcy' &&
              `
              margin-right: -2032px;
            `}
          }
          @media (min-width: 768px) and (max-width: 991px) {
            margin-right: -2035px;
            ${(props) =>
              props.page === 'zwyciezcy' &&
              `
              margin-right: -2033px;
            `}
          }
          @media (min-width: 576px) and (max-width: 767px) {
            margin-right: -2032px;
            ${(props) =>
              props.page === 'zwyciezcy' &&
              `
              margin-right: -2030px;
            `}
          }
          @media (max-width: 575px) {
            margin-right: -2021px;
            ${(props) =>
              props.page === 'zwyciezcy' &&
              `
              margin-right: -2019px;
            `}
          }
        }
        &.last {
          margin-right: -2020px;
          @media (min-width: 768px) and (max-width: 991px) {
            margin-right: -2024px;
          }
          @media (min-width: 576px) and (max-width: 767px) {
            margin-right: -2024px;
          }
          @media (max-width: 575px) {
            margin-right: -2015px;
          }
        }
      }
    }
  }
`
